import React from 'react';
import {BuildingOffice2Icon, EnvelopeIcon, PhoneIcon} from "@heroicons/react/20/solid";

function App() {
  return <>
    <div className="bg-white px-6 py-16 sm:py-24 lg:px-8">
      <div className="mx-auto max-w-2xl text-center object-center">
        <div className="flex justify-center items-center">
          <img className={'max-w-xs'} src={process.env.PUBLIC_URL + 'omw-logo.png'} alt={'Logo OMW-Energieberatungsbüro'}/>
        </div>
        <p className="lg:mt-36 mt-16 text-base font-semibold leading-7 text-green-600">Energetische Verbesserung Ihrer Immobilie</p>
        <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">OMW Energieberatung</h1>
        <p className="mt-12 text-lg leading-8 text-gray-600">
          Willkommen auf der Seite des OMW-Energieberatungsbüros. Wir haben es uns zur
          Aufgabe gemacht, unsere Kunden in allen Fragen der Energieeffizienz fachgerecht
          und transparent zu beraten.
        </p>
        <p className="mt-12 text-lg leading-8 text-gray-600 bg-orange-100 rounded-2xl p-2">
          Unser Webauftritt befindet sich aktuell noch im Aufbau. Kontaktieren Sie uns in der
          Zwischenzeit per Mail oder Telefon und erfahren Sie mehr über unsere Leistungen.
        </p>


        <div className="relative px-6 pb-20 pt-24 sm:pt-32 lg:static lg:px-8 ">
          <div className="mx-auto">
            <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden bg-gray-100 ring-1 ring-gray-900/10 lg:w-1/2">
              <svg
                  aria-hidden="true"
                  className="absolute inset-0 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
              >
                <defs>
                  <pattern
                      x="100%"
                      y={-1}
                      id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
                      width={200}
                      height={200}
                      patternUnits="userSpaceOnUse"
                  >
                    <path d="M130 200V.5M.5 .5H200" fill="none"/>
                  </pattern>
                </defs>
                <rect fill="white" width="100%" height="100%" strokeWidth={0}/>
                <svg x="100%" y={-1} className="overflow-visible fill-gray-50">
                  <path d="M-470.5 0h201v201h-201Z" strokeWidth={0}/>
                </svg>
                <rect fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)" width="100%" height="100%" strokeWidth={0}/>
              </svg>
            </div>
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">Kontakt</h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Unser Team der OMW-Energieberatung freut sich auf Ihre Kontaktanfrage.
            </p>
            <dl className="mt-10 space-y-4 text-base leading-7 text-gray-600 text-left">
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Telefon</span>
                  <PhoneIcon aria-hidden="true" className="h-7 w-6 text-gray-400"/>
                </dt>
                <dd>
                  <a href="tel:+4915566255593" className="hover:text-gray-900">
                    0155-66255593
                  </a>
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Email</span>
                  <EnvelopeIcon aria-hidden="true" className="h-7 w-6 text-gray-400"/>
                </dt>
                <dd>
                  <a href="mailto:info@omw-energieberatungsbuero.de?subject=Kontaktanfrage%20Webseite" className="hover:text-gray-900">
                    info@omw-energieberatungsbuero.de
                  </a>
                </dd>
              </div>
              <div className="flex gap-x-4">
                <dt className="flex-none">
                  <span className="sr-only">Adresse</span>
                  <BuildingOffice2Icon aria-hidden="true" className="h-7 w-6 text-gray-400"/>
                </dt>
                <dd>
                  OMW Energieberatungsbüro GbR
                  <br/>
                  Rhönblick 9
                  <br/>
                  63579 Freigericht
                </dd>
              </div>
            </dl>
          </div>
        </div>

      </div>


    </div>
  </>
}

export default App;
